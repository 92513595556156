@charset "utf-8";

@font-face {
  font-family : "score";
  font-weight: 100;
  src:url("../../font/SCDream1.otf")
}
@font-face {
  font-family : "score";
  font-weight: 200;
  src:url("../../font/SCDream2.otf")
}
@font-face {
  font-family : "score";
  font-weight: 300;
  src:url("../../font/SCDream3.otf")
}
@font-face {
  font-family : "score";
  font-weight: 400;
  src:url("../../font/SCDream4.otf")
}
@font-face {
  font-family : "score";
  font-weight: 500;
  src:url("../../font/SCDream5.otf")
}
@font-face {
  font-family : "score";
  font-weight: 600;
  src:url("../../font/SCDream6.otf")
}
@font-face {
  font-family : "score";
  font-weight: 700;
  src:url("../../font/SCDream7.otf")
}
@font-face {
  font-family : "score";
  font-weight: 800;
  src:url("../../font/SCDream8.otf")
}
@font-face {
  font-family : "score";
  font-weight: 900;
  src:url("../../font/SCDream9.otf")
}
@font-face {
  font-family : "noto";
  font-weight:100;
  src:url("../../font/NotoSansKR-Light.ttf")
}
@font-face {
  font-family : "noto";
  font-weight:400;
  src:url("../../font/NotoSansKR-Regular.ttf")
}
@font-face {
  font-family : "noto";
  font-weight:500;
  src:url("../../font/NotoSansKR-Medium.ttf")
}
@font-face {
  font-family : "noto";
  font-weight:600;
  src:url("../../font/NotoSansKR-SemiBold.ttf")
}
@font-face {
  font-family : "noto";
  font-weight:700;
  src:url("../../font/NotoSansKR-Bold.ttf")
}

html,body {
  margin:0; padding:0;
  font-family: 'score', sans-serif;
  height:100%;
}
h1,h2,h3,h4,h5,h6,p,div,span,header,aside,footer,nav,main,article,section,ul,ol,li,dl,dt,dd,em,strong,s,del,sup,sub,a,table,tr,td,th,tbody,thead,tfoot,form,input,select,option,button,fieldset,legend,label,address {
  margin:0; padding:0;
  font-size:16px; font-style:normal;
  font-weight:normal; line-height:1.0;
  letter-spacing: -0.3px;
  box-sizing: border-box;
  color:#202020;
  font-family: 'score', sans-serif;
}
fieldset,table {border:0;}
input, textarea {border:0; outline:none;}
button, a {border:0; cursor:pointer;}
ul,ol,li {list-style:none;}
table,tr,td,th {border-collapse:collapse;}
th {text-align:left;}
a {color:#202020; text-decoration:none;}
.skip{display:none;}

a:hover, button:hover {
  text-decoration: none;
}